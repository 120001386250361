// react
import React from 'react'

// third-party
import Col from 'react-bootstrap/Col'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Row from 'react-bootstrap/Row'
import { BatteryCharging } from 'react-bootstrap-icons'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'


const Navigation = () => {
    const user = useSelector((state) => state.user).data

    return (
        <Navbar collapseOnSelect expand="lg" bg="light">
            <Navbar.Brand>
                <Link to="/" style={{ color: '#38ada9' }}>
                    <Row style={{ alignItems: 'center' }}>
                        <Col>
                            <BatteryCharging size={34} />
                        </Col>
                        <Col>
                            XTRONIK
                        </Col>
                    </Row>
                </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav>
                    <NavDropdown title={user.email} id="collasible-nav-dropdown">
                        <NavDropdown.Item href="/user/logout">
                            Logout
                        </NavDropdown.Item>
                    </NavDropdown>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default Navigation