// react
import React from 'react'

// third-party
import { Route, withRouter, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'


const AuthenticatedRoute = ({ 
	component: Component, 
	...rest 
}) => {
	const user = useSelector((state) => state.user)

	return (
		<Route {...rest}
			render={(props) => (
		    	user.loggedIn
			      ? <Component {...props} />
			      : <Redirect to={{
			          pathname: '/login',
			          state: { from: props.location }
			        }} />
			)} 
		/>
	)
}

export default withRouter(AuthenticatedRoute)