// react
import React, { useEffect, useState } from 'react'

// third-party
import { useDispatch } from 'react-redux'
import QrReader from 'react-qr-reader'
import { useParameterizedQuery } from 'react-fetching-library'
import { useHistory } from 'react-router-dom'

// store
import { connect as stationConnectAction } from '../store/station'

// layout
import Layout from '../component/layout'

// api
import { getStatus } from '../api/action/station'

// component
import { vibrate } from '../component/vibration'


const Scan = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { query, payload: scanStation, loading, error, status: scanStationStatus } = useParameterizedQuery(getStatus);
    const [scanStationId, setScanStationId] = useState(null);
    const [status, setStatus] = useState(null);

    useEffect(() => {
        if (scanStationId !== null) {
            query(scanStationId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scanStationId]);

    useEffect(() => {
        if (scanStation && loading === false && error === false) {
            dispatch(
                stationConnectAction(
                    scanStationId,
                    scanStation.status,
                    scanStation.current,
                    scanStation.power
                )
            );

            history.push('/');
        } else if (loading === false && error === true) {
            if (scanStationStatus === 404) {
                setStatus(
                    <div
                        className="alert alert-danger alert-bordered has-icon"
                        role="alert"
                    >
                        <i className="la la-check alert-icon"></i> Unable to find this station, please retry.
                        </div>
                );
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scanStation, loading, error])

    const onScan = (data) => {
        if (data !== null) {
            vibrate(1000);

            const id = data.slice(2, data.length)

            setScanStationId(id);
        }
    }

    const page = (
        <>
            <h2>Scan the station QR code</h2>
            {status}
            <QrReader
                delay={300}
                onError={() => null}
                onScan={onScan}
                style={{ width: '100%', height: 'auto', margin: 'auto' }}
                facingMode="environment"
                className="mb-2"
            />
        </>
    );

    return <Layout page={page} />
}

export default Scan