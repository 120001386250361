// third-party
import flatten from 'flat'

// message
import en_EN from './messages/en-EN.json'


if (!Intl.PluralRules) {
  	require('@formatjs/intl-pluralrules/polyfill')
  	require('@formatjs/intl-pluralrules/dist/locale-data/en')
}

if (!Intl.RelativeTimeFormat) {
  	require('@formatjs/intl-relativetimeformat/polyfill')
 	require('@formatjs/intl-relativetimeformat/dist/locale-data/en')
}

export default 
{
    'en-EN': flatten(en_EN)
}