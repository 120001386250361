// react
import React from 'react'

// third-party
import { ClientContextProvider } from 'react-fetching-library'
import { IntlProvider } from 'react-intl'
import { useSelector } from 'react-redux'
import { Route, BrowserRouter, Switch, Redirect } from 'react-router-dom'

// components
import AuthenticatedRoute from './component/security/AuthenticatedRoute'
import StationConnectedRoute from './component/security/StationConnectedRoute';

// pages
import { Page404 } from './page/security';
import { PageLogin, PageLogout, PageRegistration } from './page/user';
import PageScan from './page/Scan';
import PageHome from './page/Home';

// application
import { getClient } from './api/client'
import messages from './internationalization'

// style
import 'react-input-range/lib/css/index.css';
import './assets/scss/style.scss'

export const API = `${process.env.REACT_APP_SERVER_URL}/api`

const ScrollToTop = (event) => {
    if (event && event.match.path !== event.location.pathname) {
        window.scrollTo(0, 0);
    }

    return null;
}

const App = () => {
    const locale = useSelector((state) => state.locale)

    return (
      <IntlProvider locale={locale} messages={messages[locale]}>
        <ClientContextProvider client={getClient(API)}>
          <BrowserRouter basename={process.env.PUBLIC_URL}>
            <Route component={ScrollToTop} />
            <Switch>
              {/* Home */}
              <StationConnectedRoute exact path="/" component={PageHome} />

              {/* User */}
              <Route exact path="/login" component={PageLogin} />
              <Route exact path="/user/register" component={PageRegistration} />
              <AuthenticatedRoute
                exact
                path="/user/logout"
                component={PageLogout}
              />

              {/* Scan */}
              <AuthenticatedRoute
                exact
                path="/scan"
                component={PageScan}
              />

              {/* 404 */}
              <Route exact path="/404" component={Page404} />
              <Redirect to="/404" />
            </Switch>
          </BrowserRouter>
        </ClientContextProvider>
      </IntlProvider>
    );
}

export default App