// react
import { useEffect } from 'react'


export default function addBodyClass(className) {
  	return () => useEffect(() => {
    	document.body.classList.add(className)

    	return () => document.body.classList.remove(className)
  	})
}