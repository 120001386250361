// store
import { STATION_CONNECT, STATION_DISCONNECT } from './station.type'

const initialState = { connected: false };

export default (
    state = initialState,
    action
) => {
    switch (action.type) {
        case STATION_CONNECT:
            return {
                ...state,
                connected: true,
                id: action.id,
                status: action.status,
                intensity: action.intensity,
                power: action.power
            };
        case STATION_DISCONNECT:
            return {
                connected: false
            }
        default:
            return state;
    }
};
