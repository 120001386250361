// third-party
import detectBrowserLanguage from 'detect-browser-language';

// store
import { LOCALE_CHANGE } from './locale.type';

// application
import languages from '../../internationalization/languages'

const browserLanguage = detectBrowserLanguage()

const initialState = browserLanguage !== undefined && languages.map((language) => language.locale).includes(browserLanguage) ? browserLanguage : 'en-EN';

export default (state = initialState, action) => {
    if (action.type === LOCALE_CHANGE) {
        return action.locale
    }

    return state
}
