// util
import zeroPad from '../../util/zeroPad';

export const getStatus = (id) => ({
    method: 'GET',
    endpoint: `/station/status?station_id=${id}`
});

export const setStatus = ({ stationId, status }) => ({
    method: "POST",
    endpoint: "/station/state",
    body: {
        station_id: stationId,
        value: status === true ? "true" : "false"
    }
});

export const setCurrent = ({ stationId, current }) => ({
    method: 'POST',
    endpoint: `/settings/max_current`,
    body: {
        station_id: stationId,
        value: current
    }
});

export const getHistory = ({ stationId, date: { day, month, year } }) => ({
    method: 'GET',
    endpoint: `/station/electric?station_id=${stationId}&date=${year}-${zeroPad(month, 2)}-${zeroPad(day, 2)}`
});

export const getTarrif = (stationId) => ({
    method: 'GET',
    endpoint: `/station/payment?station_id=${stationId}`
});