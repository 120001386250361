// react
import React from 'react';

// third-party
import { useIntl } from "react-intl";
import { Row, Col } from 'react-bootstrap';
import { useSelector } from "react-redux";
import { useQuery } from "react-fetching-library";

// layout
import Layout from '../component/layout';

// api
import { getTarrif } from '../api/action/station';

// style
import '../assets/scss/page/home.scss';

const Home = () => {
    // third-party
    const intl = useIntl();
    const station = useSelector((state) => state.station);
    const { payload, loading } = useQuery(getTarrif(station.id));

    const page = (
        <>
            <h2>Home</h2>
            <div className="border-top my-2" />
            <Row className="mt-4">
                <Col xs={6}>
                    <img className="station" src={`/assets/images/${station.status === 0 ? 'station' : 'station-charging'}.png`} alt="Station" />
                </Col>
                <Col xs={6}>
                    <Row>
                        <Col xs={12}>
                            <fieldset>
                                <legend>Power</legend>
                                <h2>{station.power}kW</h2>
                            </fieldset>
                        </Col>
                        <Col xs={12} className="mt-2">
                            <fieldset>
                                <legend>Energy</legend>
                                <h2>{station.intensity}kWh</h2>
                            </fieldset>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );

    return <Layout page={page} />;
};

export default Home;
