// react
import React from 'react'
import ReactDOM from 'react-dom'

// third-party
import { Provider } from 'react-redux'

// app
import App from './App'
import * as serviceWorker from './serviceWorker'

// store
import store from './store'

const Root = () => {
    return (
        <Provider store={store}>
            <App />
        </Provider>
    )
}

ReactDOM.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorker.register()