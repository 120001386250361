// application
import { USER_LOGIN, USER_REFRESH, USER_LOGOUT } from './user.type'


const initialState = {
	loggedIn: false
}

export default (state = initialState, action) => {
    switch (action.type) {
    	case USER_LOGIN:
    		return {
    			loggedIn: true,
    			data: action.data,
    			token: action.token,
                refreshToken: action.refreshToken
    		}
        case USER_REFRESH:
            return {
                ...state,
                token: action.token,
                refreshToken: action.refreshToken
            }
        case USER_LOGOUT:
            return {
                loggedIn: false
            }
    	default:
    		return state
    }
}
