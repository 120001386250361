// react
import React, { useState } from 'react'

// third-party
import classNames from 'classnames'
import { useMutation } from 'react-fetching-library'
import { useForm, ErrorMessage } from 'react-hook-form'
import { useIntl } from 'react-intl'
import LoadingBar from 'react-top-loading-bar'

// api
import { create } from '../../../api/action/user'

// component
import DelayedRedirect from '../../DelayedRedirect'
import FormErrorMessage from '../render/FormErrorMessage'


const LoginForm = () => {
    const { handleSubmit, errors, watch, register, reset, setError } = useForm()
    const intl = useIntl()
    const { mutate } = useMutation(create)

    const [status, setStatus] = useState(null)
    const [loadingPercentage, setLoadingPercentage] = useState(0);
    const watcher = watch()

    const onSubmit = async (form) => {
        setLoadingPercentage(0)

        mutate(form)
            .then(({ payload: response, error }) => {
                if (error === false) {
                    reset()

                    setStatus(
                        <>
                            <div className="alert alert-success alert-bordered has-icon" role="alert">
                                <i className="la la-check alert-icon"></i> {intl.formatMessage({ id: 'page.user.register.message.success', defaultMessage: 'Successfully registered !' })}
                            </div>
                            <DelayedRedirect to="/login" delay={1500} />
                        </>
                    )
                } else if (response) {
                    Object.entries(response).map(([key, message]) => setError(key, 'external', message));
                }
            })
            .finally(() => setLoadingPercentage(100))
    }

    return (
        <React.Fragment>
            <LoadingBar
                progress={loadingPercentage}
                height={3}
                color="#fe4d2e"
            />
            {status}
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-2">
                    <div className="form-group mb-0">
                        <label>{intl.formatMessage({ id: 'page.user.register.form.label.email', defaultMessage: 'Email address' })}</label>
                        <input
                            className={classNames('form-control', {
                                invalid: errors.email,
                                valid: !errors.email && watcher.email
                            })}
                            type="email"
                            name="email"
                            ref={register({
                                required: 'form.validator.required'
                            })}
                        />
                    </div>
                    <ErrorMessage name="email" errors={errors} as={<FormErrorMessage htmlFor="email" />} />
                </div>
                <div className="mb-2">
                    <div className="form-group mb-0">
                        <label>{intl.formatMessage({ id: 'page.user.register.form.label.password', defaultMessage: 'Password' })}</label>
                        <input 
                            className={classNames('form-control', { invalid: errors.password, valid: !errors.password && watcher.password })} 
                            type="password" 
                            name="password" 
                            ref={register({ 
                                required: 'form.validator.required',
                                minLength: { 
                                    value: 8, 
                                    message: intl.formatMessage({ id: 'form.validator.minStringLength' }, { min: 8 }) 
                                }
                            })} 
                        />
                    </div>
                    <ErrorMessage name="password" errors={errors} as={<FormErrorMessage htmlFor="password" />} />
                </div>
                <div className="mb-2">
                    <div className="form-group mb-0">
                        <label>{intl.formatMessage({ id: 'page.user.register.form.label.passwordConfirmation', defaultMessage: 'Password confirmation' })}</label>
                        <input
                            className={classNames('form-control', { invalid: errors.passwordConfirmation, valid: !errors.passwordConfirmation && watcher.passwordConfirmation })}
                            type="password"
                            name="passwordConfirmation"
                            ref={register({
                                required: 'form.validator.required',
                                validate: value => value === watch('password') || intl.formatMessage({ id: 'form.validator.isPasswordMatch' })
                            })}
                        />
                    </div>
                    <ErrorMessage name="passwordConfirmation" errors={errors} as={<FormErrorMessage htmlFor="passwordConfirmation" />} />
                </div>
                <button className="btn btn-primary btn-rounded btn-block btn-loading" type="submit">
                    {intl.formatMessage({ id: 'page.user.register.form.submit', defaultMessage: 'Register' })}
                </button>
            </form>
        </React.Fragment>
    )
}

export default LoginForm