// third-party
import { useDispatch } from 'react-redux'

// application
import { logout } from '../../store/user'
import { disconnect as stationDisconnect } from '../../store/station';

const PageLogout = () => {
    const dispatch = useDispatch()

    dispatch(logout())
    dispatch(stationDisconnect());

    return null
}

export default PageLogout