export const login = (form) => ({
	  method: 'POST',
  	endpoint: '/auth/login',
    body: {
      email: form.email,
      password: form.password
    }
})

export const create = (form) => ({
    method: 'POST',
    endpoint: '/auth/signup',
    body: {
      email: form.email,
      password: form.password
    },
    config: {
      noToken: true
    }
})

export const refresh = (token) => ({
    method: 'POST',
    endpoint: '/auth/refresh',
    body: {
      'refresh_token': token
    },
    config: {
      noRetry: true,
      noToken: true
    }
})