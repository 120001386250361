import { USER_LOGIN, USER_REFRESH, USER_LOGOUT } from './user.type'


export const login = (token, refreshToken, email) => ({
	type: USER_LOGIN,
	data: { email },
	token,
	refreshToken
})

export const refresh = (token, refreshToken) => ({
	type: USER_REFRESH,
	token,
	refreshToken
})

export const logout = () => ({
	type: USER_LOGOUT
})