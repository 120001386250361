// react
import React from 'react'

// third-party
import Container from 'react-bootstrap/Container'

// theme
import Navigation from './Navigation'


const Layout = ({ page }) => {
    return (
        <>
            <Navigation />
            <Container style={{ paddingTop: 10 }}>
                {page}
            </Container>
        </>
    )
}

export default Layout