// third-party
import { createClient } from 'react-fetching-library'

// API
import { requestHostInterceptor, requestAuthorizationInterceptor } from './request.interceptor'
import { responseAPIErrorInterceptor } from './response.interceptor'


export const getClient = (host) => {
	return createClient({
		requestInterceptors: [requestHostInterceptor(host), requestAuthorizationInterceptor],
		responseInterceptors: [responseAPIErrorInterceptor]
	})
}