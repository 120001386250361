import { STATION_CONNECT, STATION_DISCONNECT } from './station.type'

export const connect = (id, status, intensity, power) => ({
    type: STATION_CONNECT,
    id,
    status,
    intensity,
    power
})

export const disconnect = () => ({
    type: STATION_DISCONNECT
})