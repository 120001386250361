// react
import React from 'react'

// third-party
import { useIntl } from 'react-intl'


const FormErrorMessage = ({ children: message, ...props }) => {
	const intl = useIntl()

	return (
		<label className="invalid-feedback" style={{display: 'inline-block'}} {...props}>
			{intl.formatMessage({ id: message, defaultMessage: message })}
		</label>
	)
}

export default FormErrorMessage