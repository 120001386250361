// react
import React from 'react'

// third-party
import { useIntl } from 'react-intl'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { Link } from 'react-router-dom'

// application
import addBodyClass from '../../component/hook/add-body-class.hook'

// form
import LoginForm from '../../component/form/user/Login'

// style
import '../../assets/scss/page/user/login.scss'

const PageLogin = () => {
    const intl = useIntl()

    addBodyClass('page-user-login__body')();

    return (
        <Container>
            <Row>
                <Col md={12} className="min-vh-100 d-flex flex-column justify-content-center mt-2 mb-2">
                    <Row>
                        <Col md={8} lg={6} className="mx-auto">
                            <Card className="rounded shadow shadow-sm">
                                <Card.Header>
                                    <Card.Title>XTRONIK</Card.Title>
                                    <Card.Subtitle className="text-muted">{intl.formatMessage({ id: 'page.user.login.title', defaultMessage: 'Login' })}</Card.Subtitle>
                                </Card.Header>
                                <Card.Body>
                                    <LoginForm />
                                    <Link to="/user/register">
                                        <Button variant="primary" className="btn-block btn-rounded mt-2 mb-3">{intl.formatMessage({ id: 'page.user.login.registration', defaultMessage: 'Registration' })}</Button>
                                    </Link>
                                    <Button variant="secondary" className="btn-block btn-rounded">{intl.formatMessage({ id: 'page.user.login.forgottenPassword', defaultMessage: 'Forgotten password ?' })}</Button>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}

export default PageLogin