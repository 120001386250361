// react
import React from 'react';

// third-party
import { withRouter, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

// component
import AuthenticatedRoute from './AuthenticatedRoute';


const StationConnectedRoute = ({
    component: Component,
    ...rest
}) => {
    const station = useSelector((state) => state.station);

    return (
        <AuthenticatedRoute {...rest}
            component={(props) => (
                station.connected !== false
                    ? <Component {...props} />
                    : <Redirect to={{
                        pathname: '/scan',
                        state: { from: props.location }
                    }} />
            )}
        />
    )
}

export default withRouter(StationConnectedRoute);