// application
import store from '../store'


export const requestHostInterceptor = host => () => async action => {
  let endpoint = '';

  if (!action.count) {
    endpoint = host
  }

  return {
	...action,
    endpoint: `${endpoint}${action.endpoint}`,
    count: action.count ? ++action.count : 1
  };
};

export const requestAuthorizationInterceptor = () => async action => {
  const user = store.getState().user;

  if (
    user.loggedIn === true &&
    ((action.config && action.config.noToken === false) || !action.config)
  ) {
    const auth = { authorization: `Bearer ${user.token}` };

    if (typeof action.headers === typeof {}) {
      action.headers = { ...action.headers, ...auth };
    } else {
      action.headers = auth;
    }
  }

  return action;
};