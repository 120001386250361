// third-party
import { combineReducers } from 'redux';

// reducers
import localeReducer from './locale';
import userReducer from './user';
import stationReducer from './station';


export default combineReducers({
    locale: localeReducer,
    user: userReducer,
    station: stationReducer
})
