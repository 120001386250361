// third-party
import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'

// reducer
import reducers from './root.reducer'


function load() {
    let state;

    try {
        state = localStorage.getItem('state')

        if (typeof state === 'string') {
            state = JSON.parse(state)
        }
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
    }

    return state || undefined;
}

function save() {
    try {
        localStorage.setItem('state', JSON.stringify(store.getState()))
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
    }
}

const store = createStore(reducers, load(), compose(
    applyMiddleware(thunk)
));

store.subscribe(() => save())

export default store