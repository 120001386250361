// react
import React from 'react'

// third-party
import { useIntl } from 'react-intl'
import { useHistory, Link } from 'react-router-dom'

const Page404 = () => {
    const history = useHistory()
	const intl = useIntl()

    const goBack = (event) => {
        event.preventDefault()

        history.goBack()
    }

	return (
		<div className="box-wrapper">
            <div className="error-content py-3">
                <h1 className="error-code text-primary font-weight-normal" style={{fontSize: 120}}>
                	404
                </h1>
                <h3 className="text-muted mb-5">
                	{intl.formatMessage({ id: 'page.security.not-found.message', defaultMessage: 'Sorry' })}
                </h3>
                <div className="mb-5">
                	<Link to="/">
                		<div className="btn btn-outline-primary">
	                		<span className="btn-icon">
	                			<i className="material-icons">home</i>{intl.formatMessage({ id: 'page.security.not-found.go-back-home', defaultMessage: 'Go home' })}
	                		</span>
	                	</div>
                	</Link>
                    <a href="!#" style={{ marginLeft: 10 }} onClick={goBack}>
                        <div className="btn btn-outline-warning">
                            <span className="btn-icon">
                                <i className="material-icons">arrow_back</i>{intl.formatMessage({ id: 'page.security.not-found.go-back', defaultMessage: 'Go back' })}
                            </span>
                        </div>
                    </a>
                </div>
            </div>
        </div>
	)
}

export default Page404