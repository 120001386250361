// action
import { refresh as refreshUserToken } from "./action/user";

// application
import store from "../store";
import {
  refresh as refreshUserTokenAction,
  logout as logoutUserAction
} from "../store/user";

export const responseAPIErrorInterceptor = client => async (
  action,
  response
) => {
  if (response.error) {
    switch (response.status) {
      case 401:
        const refreshToken = String(store.getState().user.refreshToken);

        const { payload: data, error } = await client.query(
          refreshUserToken(refreshToken)
        );

        if (error === false) {
          store.dispatch(
            refreshUserTokenAction(data.access_token, data.refresh_token)
          );

          return client.query(action);
        } else {
          store.dispatch(logoutUserAction());
        }

        break;
      default:
        break;
    }
  }

  return response;
};
