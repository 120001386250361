// react
import React, { useState } from 'react'

// third-party
import classNames from 'classnames'
import { useMutation } from 'react-fetching-library'
import { useForm, ErrorMessage } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import LoadingBar from 'react-top-loading-bar'

// api
import { login } from '../../../api/action/user'

// store
import { login as loginAction } from '../../../store/user'

// component
import DelayedRedirect from '../../DelayedRedirect'
import FormErrorMessage from '../render/FormErrorMessage'


const LoginForm = () => {
	const dispatch = useDispatch()
	const { handleSubmit, errors, watch, register, setError } = useForm()
	const intl = useIntl()
	const { mutate } = useMutation(login)

	const [status, setStatus] = useState(null)
	const [loadingPercentage, setLoadingPercentage] = useState(0);
	const watcher = watch()

	const onSubmit = async (form) => {
		setLoadingPercentage(0)

		mutate(form)
			.then(({ payload: response, error }) => {
				if (error === false) {
					const {
						access_token: token,
						refresh_token: refreshToken
					} = response;

					dispatch(loginAction(token, refreshToken, form.email))

					setStatus(
						<>
							<div className="alert alert-success alert-bordered has-icon" role="alert">
								<i className="la la-check alert-icon"></i> {intl.formatMessage({ id: 'page.user.login.message.success', defaultMessage: 'Successfully logged in !' })}
							</div>
							<DelayedRedirect to="/" delay={1500} />
						</>
					)
				} else if (response) {
					Object.entries(response).map(([key, message]) => setError(key, 'external', message));
				}
			})
			.finally(() => setLoadingPercentage(100))
	}

	return (
		<React.Fragment>
			<LoadingBar
				progress={loadingPercentage}
				height={3}
				color="#fe4d2e"
			/>
			{status}
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="mb-2">
					<div className="form-group mb-0">
						<label>{intl.formatMessage({ id: 'page.user.login.form.label.email', defaultMessage: 'Email address' })}</label>
						<input
							className={classNames('form-control', {
								invalid: errors.email,
								valid: !errors.email && watcher.email
							})}
							type="text"
							name="email"
							ref={register({
								required: 'form.validator.required'
							})}
						/>
					</div>
					<ErrorMessage name="email" errors={errors} as={<FormErrorMessage htmlFor="email" />} />
				</div>
				<div className="mb-2">
					<div className="form-group mb-0">
						<label>{intl.formatMessage({ id: 'page.user.login.form.label.password', defaultMessage: 'Password' })}</label>
						<input className={classNames('form-control', { invalid: errors.password, valid: !errors.password && watcher.password })} type="password" name="password" ref={register({ required: 'form.validator.required' })} />
					</div>
					<ErrorMessage name="password" errors={errors} as={<FormErrorMessage htmlFor="password" />} />
				</div>
				<div className="flexbox mb-3">
					<label className="ui-switch switch-solid">
						<input type="checkbox" name="rememberMe" ref={register({ required: false })} />
						<span className="ml-0"></span> {intl.formatMessage({ id: 'page.user.login.form.label.remember-me', defaultMessage: 'Remember me' })}
					</label>
				</div>
				<button className="btn btn-primary btn-rounded btn-block btn-loading" type="submit">
					{intl.formatMessage({ id: 'page.user.login.form.submit', defaultMessage: 'Login' })}
				</button>
			</form>
		</React.Fragment>
	)
}

export default LoginForm